import { useEffect } from 'react';
import { GetStaticPropsContext } from 'next';
import { setupLocale } from '@core/i18n';
import MetaHead from '@core/meta-head';
import { useAnalytics } from '@core/analytics';
import NotFoundError from 'src/features/common/errors/NotFoundError';

export default function Index() {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackPage('ERROR_404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaHead />
      <NotFoundError />
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: await setupLocale(locale),
    },
  };
}
